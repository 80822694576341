<template>

  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <!-- Filters -->
    <log-filters
      :type-filter.sync="typeFilter"
      :user-filter.sync="userFilter"
      :relation-model-filter.sync="relationModelFilter"
      :type-options="typeOptions"
      :user-options="userOptions"
      :relation-model-options="relationModelOptions"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <!--            <div class="d-flex align-items-center justify-content-end">-->
            <!--              <b-form-input-->
            <!--                v-model="searchQuery"-->
            <!--                class="d-inline-block mr-1"-->
            <!--                placeholder="Zoeken..."-->
            <!--              />-->
            <!--            </div>-->
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refLogListTable"
        class="position-relative"
        :items="fetchLogs"
        responsive
        :fields="tableLogColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold"
          >
            {{ data.item.id }}
          </b-link>
        </template>
        <template #cell(timestamp)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
          </div>
        </template>
        <template #cell(object)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveRelationModel(data.item.relationModel) }}</span>
          </div>
        </template>
        <template #cell(objectID)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.relationID }}</span>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveTypeName(data.item.type) }}</span>
          </div>
        </template>
        <template #cell(user)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ getUserName(data.item.user) }}</span>
          </div>
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.requestIP }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div
            class="text-nowrap"
          >
            <feather-icon
              v-if="data.item.data&&JSON.parse(data.item.data).length>0"
              :id="`log-row-${data.item.id}-data-icon`"
              class="cursor-pointer mx-1 feather-hover"
              icon="InfoIcon"
              size="16"
              style="color:#fdb813"
            />
            <b-tooltip
              v-if="data.item.data&&JSON.parse(data.item.data).length>0"
              :target="`log-row-${data.item.id}-data-icon`"
              placement="left"
            >
              <p class="mb-0">
                {{ getLogDetails(JSON.parse(data.item.data)) }}
                <!--                Veld: {{ JSON.parse(data.item.data)[0].field }}-->
                <!--                <br/>Oud: {{ JSON.parse(data.item.data)[0].old }}-->
                <!--                <br/>Nieuw: {{ JSON.parse(data.item.data)[0].new }}-->
              </p>
            </b-tooltip>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalActivities"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink, BPagination, BOverlay, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LogFilters from './LogFilters.vue'
import useLogList from './useLogList'
import logStoreModule from './logStoreModule'

export default {
  components: {
    LogFilters,
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    vSelect,
    BTooltip,
  },
  data() {
    return {
      userOptions: [],
    }
  },
  beforeMount() {
    store.dispatch('apps-log/fetchUsers', { isAll: true })
      .then(response => {
        this.userOptions = response.data.items
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van gebruikers',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    getUserName(user) {
      if (user !== null && user.fullname) {
        return user.fullname
      }

      return 'Server'
    },
    getLogDetails(data) {
      let logDetailAll = ''
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = data.length; i < len; i++) {
        let logDetail = ''
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const key in data[i]) {
          logDetail = logDetail.concat(`${key}: ${data[i][key]}`, ', ')
        }
        logDetail = logDetail.substring(0, logDetail.length - 2)
        logDetailAll = logDetailAll.concat(logDetail, '; ')
        logDetailAll = logDetailAll.substring(0, logDetailAll.length - 2)
      }
      return logDetailAll
    },
  },
  setup() {
    const LOG_APP_STORE_MODULE_NAME = 'apps-log'

    // Register module
    if (!store.hasModule(LOG_APP_STORE_MODULE_NAME)) store.registerModule(LOG_APP_STORE_MODULE_NAME, logStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOG_APP_STORE_MODULE_NAME)) store.unregisterModule(LOG_APP_STORE_MODULE_NAME)
    })

    const {
      fetchLogs,
      tableLogColumns,
      perPage,
      currentPage,
      typeFilter,
      userFilter,
      totalActivities,
      dataMeta,
      perPageOptions,
      refLogListTable,

      refetchData,

      relationModelOptions,
      relationModelFilter,
      typeOptions,
      resolveTypeName,
      showOverlay,
      resolveRelationModel,
    } = useLogList()

    return {
      fetchLogs,
      tableLogColumns,
      perPage,
      currentPage,
      relationModelFilter,
      typeFilter,
      userFilter,
      totalActivities,
      dataMeta,
      perPageOptions,
      refLogListTable,

      refetchData,

      relationModelOptions,
      typeOptions,
      resolveTypeName,
      showOverlay,
      resolveRelationModel,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
